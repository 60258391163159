<template>
  <div class="google_map_wrapper" ref="mapWrapperDiv">
    <div class="google_map map-hidden" ref="mapDiv">
    </div>

    <loading v-if="!mapLoaded" label="Loading Map..." />

    <template v-if="mapLoaded">
      <slot />
    </template>
  </div>
</template>

<script>
import { Loader as GoogleMapsApiLoader } from '@googlemaps/js-api-loader'

import { InterpretationFactory } from '@/maps/InterpretationFactory'
import { GoogleMapUtils } from '@/maps/GoogleMapUtils'
import { GeoUtil } from '@/geo/GeoUtil'

import { toRaw } from 'vue'

function debounce(callback, timeoutInMilliseconds) {
  let timeout = null

  return () => {
    const invokeCallback = function () {
      timeout = null
      callback()
    }

    if (timeout != null) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(invokeCallback, timeoutInMilliseconds)
  }
}

export default {
  props: {
    apiKey: { type: String, required: true }
  },
  data: function () {
    return {
      provider: {
        api: null,
        map: null,
        interpretationFactory: null,
        googleMapUtils: null,
        zoomLevel: 1,
        boundsGeo: null,
        mapBecameIdle: false
      }
    }
  },
  computed: {
    apiLoaded: (vm) => {
      return Boolean(vm.provider.api) && Boolean(vm.provider.map) && Boolean(vm.provider.interpretationFactory)
    },
    mapLoaded: (vm) => vm.apiLoaded && vm.provider.mapBecameIdle
  },
  provide() {
    return {
      provider: this.provider
    }
  },
  methods: {
  },
  mounted: async function () {
    const loader = new GoogleMapsApiLoader({
      apiKey: this.apiKey,
      version: 'beta',
      mapTypeId: 'hybrid',
      // mapTypeId: "NoLabels",
      //libraries: ['drawing']
      libraries: []
    })

    let google = null

    const [libCore, libMaps, libMarker, libGeocoding] = await Promise.all([
      loader.importLibrary('core'),
      loader.importLibrary('maps'),
      loader.importLibrary('marker'),
      loader.importLibrary('geocoding')
    ])
    
    let api = {...libCore, ...libMaps, ...libMarker, ...libGeocoding}

    let mapOptions = GoogleMapUtils.DefaultMapOptions()
    let usa = { lat: 37.09024, lng: -95.712891 }
    mapOptions.mapId = '7b79128ec73ebb05'
    mapOptions.center = usa
    mapOptions.zoom = 5
    //mapOptions.mapId = '7b79128ec73ebb05'
    // mapOptions.mapTypeControlOptions = {
    //   mapTypeIds: ["roadmap", "satellite", "hybrid", "terrain", "SimplePuncherStyle"],
    // }
    //       mapOptions.styles = [{
    //   "stylers": [{ 
    //      "hue": "#ff0022"
    //    }, {
    //      "saturation": -16
    //    }, {
    //      "lightness": -5
    //    }]
    // }];

    let map = toRaw(new api.Map(this.$refs.mapDiv, mapOptions))
    map.setTilt(0)

    this.provider.interpretationFactory = new InterpretationFactory(api, map, this.store)
    this.provider.googleMapUtils = new GoogleMapUtils(api, map,
      this.store, this.$refs.mapDiv, this.$refs.mapWrapperDiv)
    this.provider.api = api
    this.provider.map = map

    function addControls() {
      const positions = ["TOP_CENTER", "TOP_RIGHT", "LEFT_TOP", "RIGHT_TOP",
        "LEFT_CENTER", "RIGHT_CENTER", "LEFT_BOTTOM", "TOP_LEFT",
        "RIGHT_BOTTOM", "BOTTOM_CENTER", "BOTTOM_LEFT", "BOTTOM_RIGHT"]
      positions.forEach(p => {
        const control = map.controls[api.ControlPosition[p]]
        
        const target = `map-controls-${p}`

        const targetDiv = document.createElement('div')
        targetDiv.setAttribute('id', target)

        control.push(targetDiv)
      })    
    }

    const updateBoundsAndZoomLevel = () => {
      const bounds = map.getBounds() 
      if(! bounds) {
        return
      }
      
      if (bounds.isEmpty()) {                
        return
      } 

      const sw = bounds.getSouthWest()
      const ne = bounds.getNorthEast()
      const boundsEmpty = Number.isNaN(sw.lat()) || Number.isNaN(sw.lng()) || Number.isNaN(ne.lat()) || Number.isNaN(ne.lng())
      if (boundsEmpty) { 
        return
      }

      this.provider.zoomLevel = map.getZoom()
      const bbox = this.provider.googleMapUtils.latLngBoundsToBbox(bounds)
      this.provider.boundsGeo = GeoUtil.GeoJson.bboxPolygon(bbox)
    }

    addControls()

    api.event.addListenerOnce(map, 'idle', () => {
      updateBoundsAndZoomLevel()

      const interval = setInterval(() => {
        const testElement = document.getElementById(`map-controls-BOTTOM_RIGHT`)

        if(testElement?.parentElement) {
          this.provider.mapBecameIdle = true
          clearInterval(interval)
        }
      }, 333);
    })

    api.event.addListener(map, 'bounds_changed', debounce(() => {
      updateBoundsAndZoomLevel()
    }, 400))
  }
}
</script>

<style lang="css">
.google_map_wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.google_map {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 0;
  background: var(--primary-darker);
}

.map-hidden {
  opacity: 0.0;
  z-index: -1000;
}
</style>
