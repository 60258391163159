<template>
    <div>
        <loading v-if="refreshing" :absolute="true" label="Checking Roles..." />

        <template v-if="isValid">
            <slot />
        </template>
        <template v-else-if="isInvalid">
            <div class="body">
                <div class="centerInfo">
                    <h2>Your account has been placed on hold.</h2>

                    <h2>Please call <a href="tel:+18002779172">1-800-277-9172</a> or <a
                            href="mailto:pipeplanner@deltapl.com?subject=Account%20Status">pipeplanner@deltapl.com</a>
                    </h2>

                    <button @click.stop="refresh">Retry</button>
                    <button class="ml-2" @click.stop="logout">Logout</button>
                </div>
            </div>
        </template>
        <template v-else>
            <loading :absolute="true" label="Checking Roles..." />
        </template>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue'

const auth0 = useAuth0()

const isInvalid = computed(() => {
    const user = auth0.user?.value
    if (!user) {
        return false
    }

    const roles = user['https://deltaplastics.com/roles'] || []

    return roles.some(r => r === 'InvalidCustomer')
})

const isValid = computed(() => {
    const user = auth0.user?.value
    if (!user) {
        return false
    }

    const roles = user['https://deltaplastics.com/roles'] || []

    return !roles.some(r => r === 'InvalidCustomer')
})

const refreshing = ref(false)

async function refresh() {
    try {
        refreshing.value = true

        console.log("Checking session...")

        await auth0.checkSession({
            cacheMode: 'off'
        })
    }
    catch (e) {
        console.error(e)
    }
    finally {
        refreshing.value = false
    }

}

async function logout() {
    auth0.logout({
        logoutParams: {
            returnTo: window.location.origin,
        },
    })
}
</script>

<style lang="css" scoped>
.centerInfo {
    color: var(--secondary-light);
    font-weight: bolder;
    margin: auto;
    width: 50%;
    text-align: center;
    padding: 10px;
}

.logoutButton {
    border: 1px var(--secondary-light) solid;
    font-size: large;
}

.body {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    background: var(--bg0);
}
</style>