import './assets/main.css'

import { createApp, watch } from 'vue'
import { createAuth0 } from '@auth0/auth0-vue'
import { createStore } from 'vuex'

import App from '@/App'
import router from '@/router'
import Loading from '@/components/Loading.vue'
import Pp4Header from '@/components/Pp4Header'
import Pp4Footer from '@/components/Pp4Footer'
import Pp4Marker from '@/components/maps/Marker'
import Hint from '@/components/Hint'
import ToggleButton from '@/components/ToggleButton'
import IconButton from '@/components/IconButton'
import FullPageForm from '@/components/FullPageForm'
import RadioButton from '@/components/RadioButton'
import Pp4Label from '@/components/maps/Label'
import FlowRateSelect from '@/components/FlowRateSelect'
import Pp4Icon from '@/components/Pp4Icon'
import FieldLayoutSelect from '@/components/FieldLayoutSelect'
import Pp4Polyline from '@/components/maps/Polyline'
import Pp4Poly from '@/components/maps/Poly'
import MapElement from '@/components/maps/MapElement'
import SoilTypeSelect from '@/components/SoilTypeSelect'
import Pp4Field from '@/components/maps/Pp4Field'
import GpsDataSelect from '@/components/GpsDataSelect'
import ContextMenu from '@/components/maps/ContextMenu'
import Pp4Dialog from '@/components/Pp4Dialog'
import MapControls from '@/components/maps/MapControls'
import DesignParams from '@/components/maps/design/DesignParams'
import FurrowSet from '@/components/maps/FurrowSet'
import PipePath from '@/components/maps/irrigation_systems/PipePath'
import ElevationField from '@/components/maps/irrigation_systems/ElevationField'
import WaterSource from '@/components/maps/irrigation_systems/WaterSource'
import PipePathMenu from '@/components/maps/irrigation_systems/PipePathMenu'
import PipeSegment from '@/components/maps/irrigation_systems/PipeSegment'
import PointMarker from '@/components/maps/irrigation_systems/PointMarker'
import MidpointMarker from '@/components/maps/irrigation_systems/MidpointMarker'
import EditPipePathPath from "@/components/maps/irrigation_systems/EditPipePathPath";
import RemoveMarker from '@/components/maps/irrigation_systems/RemoveMarker'
import AddLeveePaths from '@/components/maps/edit_levees/AddLeveePaths'
import RemoveLeveePaths from '@/components/maps/edit_levees/RemoveLeveePaths'
import EditLeveePaths from '@/components/maps/edit_levees/EditLeveePaths'
import PipePathWateredAreas from '@/components/maps/irrigation_systems/PipePathWateredAreas'
import IrrigationSystemContextMenu from '@/components/maps/irrigation_systems/IrrigationSystemContextMenu'
import JdWizard from '@/components/jd/JdWizard'
import JdFlagExporter from '@/components/jd/JdFlagExporter'
import JdBeginWizardButton from '@/components/jd/JdBeginWizardButton'
import JdGuidanceLinesImport from '@/components/jd/JdGuidanceLinesImport'
import EndOfPipeSelectDialog from '@/components/maps/irrigation_systems/EndOfPipeSelectDialog'
import IrrigationSystemDialog from '@/components/maps/irrigation_systems/IrrigationSystemDialog'
import ElevationLineOverlay from '@/components/maps/ElevationLineOverlay'
import ElevationPointOverlay from '@/components/maps/ElevationPointOverlay'
import GeoJson from '@/components/maps/GeoJson'
import NextStep from '@/components/maps/NextStep'
import Pp4Image from '@/components/Pp4Image'
import FieldTile from "@/components/FieldTile.vue";
import HeaderCancelButton from '@/components/maps/HeaderCancelButton'
import MapCancelButton from '@/components/maps/MapCancelButton'
import PointMarkerDragPreview from '@/components/maps/irrigation_systems/PointMarkerDragPreview'

import { Pp4DialogPlugin } from '@/plugins/dialog'
import { StaticImagesPlugin } from '@/maps/StaticImagesPlugin'
import { AnalyticsPlugin } from '@/plugins/analytics'
import { TelemetryPlugin } from '@/plugins/telemetry'
import { useHoleDesignsProcessor } from '@/composables/useHoleDesignsProcessor'
import { BuildPp4Store } from '@/store/Pp4Store'

import VueGtag from 'vue-gtag'
// import registerServiceWorker from './registerServiceWorker'
import { createApi } from '@/plugins/api'
import { useApi } from './plugins/api'

async function main() {
  let env = {}
  try {
    const appEnvRequest = await fetch('/vue-app-env')
    env = await appEnvRequest.json()
  } catch (e) {
    alert('Server Down -- Refresh or Contact Delta Support')
    return
  }

  const app = createApp(App)

  const SENTRY_DSN = env['VUE_APP_SENTRY_DSN']
  const SENTRY_ENV = env['VUE_APP_SENTRY_ENV']
  const SENTRY_TRACING_SAMPLE_RATE = env['VUE_APP_SENTRY_TRACING_SAMPLE_RATE']

  app.use(TelemetryPlugin, { dsn: SENTRY_DSN, env: SENTRY_ENV, tracesSampleRate: SENTRY_TRACING_SAMPLE_RATE })
  const $telemetry = app.config.globalProperties.$telemetry

  app.provide('$env', env)

  app.component('loading', Loading)
  app.component('pp4-header', Pp4Header)
  app.component('next-step', NextStep)
  app.component('header-cancel-button', HeaderCancelButton)
  app.component('map-cancel-button', MapCancelButton)
  app.component('pp4-footer', Pp4Footer)
  app.component('pp4-marker', Pp4Marker)
  app.component('pipe-segment', PipeSegment)
  app.component('geo-json', GeoJson)
  app.component('hint', Hint)
  app.component('fullpageform', FullPageForm)
  app.component('toggle-button', ToggleButton)
  app.component('pp4-icon', Pp4Icon)
  app.component('icon-button', IconButton)
  app.component('radio-button', RadioButton)
  app.component('flow-rate-select', FlowRateSelect)
  app.component('field-layout-select', FieldLayoutSelect)
  app.component('soil-type-select', SoilTypeSelect)
  app.component('gps-data-select', GpsDataSelect)
  app.component('context-menu', ContextMenu)
  app.component('pp4-field', Pp4Field)
  app.component('pp4-polyline', Pp4Polyline)
  app.component('map-element', MapElement)
  app.component('pp4-poly', Pp4Poly)
  app.component('pp4-label', Pp4Label)
  app.component('pp4-dialog', Pp4Dialog)
  app.component('map-controls', MapControls)
  app.component('design-params', DesignParams)
  app.component("furrow-set", FurrowSet)
  app.component("pipe-path", PipePath)
  app.component("elevation-field", ElevationField)
  app.component("elevation-line-overlay", ElevationLineOverlay)
  app.component("elevation-point-overlay", ElevationPointOverlay)
  app.component("point-marker", PointMarker)
  app.component("midpoint-marker", MidpointMarker)
  app.component("remove-marker", RemoveMarker)
  app.component("water-source", WaterSource)
  app.component("pipe-path-menu", PipePathMenu)
  app.component("add-levee-paths", AddLeveePaths)
  app.component("remove-levee-paths", RemoveLeveePaths)
  app.component("edit-levee-paths", EditLeveePaths)
  app.component("pipe-path-watered-areas", PipePathWateredAreas)
  app.component("irrigation-system-context-menu", IrrigationSystemContextMenu)
  app.component("jd-wizard", JdWizard)
  app.component("jd-flag-exporter", JdFlagExporter)
  app.component("jd-begin-wizard-button", JdBeginWizardButton)
  app.component("jd-guidance-lines-import", JdGuidanceLinesImport)
  app.component("edit-pipe-path-path", EditPipePathPath)
  app.component("end-of-pipe-select-dialog", EndOfPipeSelectDialog)
  app.component("irrigation-system-dialog", IrrigationSystemDialog)
  app.component('point-marker-drag-preview', PointMarkerDragPreview)
  app.component('pp4-image', Pp4Image)
  app.component('field-tile', FieldTile)

  app.use(router)
  app.provide('$router', router)

  // Google Analytics
  {
    const GoogleAnalyticsMeasurementId = env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID

    app.use(
      VueGtag,
      {
        config: {
          id: GoogleAnalyticsMeasurementId
        }
      },
      router
    )

    const gtag = app.config.globalProperties.$gtag

    app.use(AnalyticsPlugin, { gtag })
  }

  // Auth, Auth, Pp4Store
  {
    // const SelectFarmRoute = router.resolve({ name: 'SelectFarm' })
    const redirectUri = new URL('/', window.location.origin)
    console.log('Auth Redirect URI: ' + redirectUri)

    const auth = createAuth0({
      domain: env.VUE_APP_AUTH0_DOMAIN,
      clientId: env.VUE_APP_AUTH0_CLIENT_ID,
      cacheLocation: 'localstorage',
      authorizationParams: {
        // connection: "email",
        // prompt: 'select_account',
        // display: "wap",oca
        // screen_hint: "login",
        // login_hint: "mpekar@revolutioncompany.com",
        redirect_uri: redirectUri,
        audience: env.VUE_APP_AUTH0_API_AUDIENCE
      },
      useRefreshTokens: true
    })

    watch(() => auth.user.value, () => {
      const user = auth?.user?.value || {}

      if (user?.sub) {
        $telemetry.setUser({
          id: user.sub,
          email: user.email
        })
      }
      else {
        $telemetry.setUser(null)
      }
    })

    auth.isJohnDeereDealer = function () {
      const roles = this.user.value["https://deltaplastics.com/roles"];
      if (!roles) {
        return false;
      }

      return roles.some((role) => role == "John Deere Dealer");
    }

    app.use(auth)

    app.use(createApi({ auth }))

    const api = useApi()

    const pp4Store = createStore(BuildPp4Store(api))
    app.use(pp4Store)
    app.provide('$store', pp4Store)

    const analytics = app.config.globalProperties.$analytics

    const holeDesignsProcessor = useHoleDesignsProcessor(pp4Store, { analytics })
    app.provide("$holeDesignsProcessor", holeDesignsProcessor)
  }

  // Static images plugin
  {
    const GoogleMapsApiKey = env.VUE_APP_GOOGLE_MAPS_API_KEY

    if (GoogleMapsApiKey == null || GoogleMapsApiKey.length < 1) {
      console.error('Invalid VUE_APP_GOOGLE_MAPS_API_KEY: ' + GoogleMapsApiKey)
    }

    app.use(StaticImagesPlugin, {
      apiKey: GoogleMapsApiKey,
      baseUrl: 'https://maps.googleapis.com/maps/api/staticmap?'
    })
    const $staticImages = app.config.globalProperties.$staticImages
    app.provide('$staticImages', $staticImages)
  }

  app.use(Pp4DialogPlugin)
  const $dialog = app.config.globalProperties.$dialog
  app.provide('$dialog', $dialog)

  app.mount('#app')
}

async function invokeMain() {
  try {
    await main()
  } catch (e) {
    const message = 'Site May Be Down -- Please Check Internet Connection and Refresh\n\n' + e
    console.error(e)
    // alert(message)
  }
}

invokeMain()
