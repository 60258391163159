<template>
    <div class="main_menu">
        <label class="labels" :id="activePage == 'Select Farm' ? 'pageIsActive' : ''" @click="openHomePage"> <i
                class="fa fa-home"></i>Home</label>
        <a href="https://www.youtube.com/channel/UCIg9hLW7k4lmXRtP7AbpkJQ" target="blank"><label
                class="labels anchor"><i class="fa fa-play-circle"></i>Help Videos</label></a>

        <a :href="myAccountEditProfileUrl" target="blank">
            <label class="labels" alt="My Delta/Revolution Account"><i class="fa fa-user"></i>My Account</label>
        </a>

        <img src="@/assets/JDOC-tm-white.svg" @click="openJohnDeere" class="jd-ops-center-img" />

        <template v-if="$auth.isAuthenticated && isAdmin">
            <label class="labels" @click="routeTransferFarm" :id="activePage == 'Transfer Farm' ? 'pageIsActive' : ''">
                <i class="fa fa-share"></i>Transfer Farms</label>
            <label class="labels" @click="reports"><i class="fa fa-paperclip"></i>Reports</label>
            <label class="labels" @click="loginAs"><i class="fa fa-user-circle"></i>Login As User</label>
            <label class="labels" @click="jdAccess"><i class="fa fa-key"></i>John Deere Access</label>
            <template v-if="$auth.isAuthenticated && isAdmin && puppetUser">
                <label @click="logoutAs" class="labels" style="color: orange;">Logout As:
                    {{ puppetUser.email }} </label>
            </template>
        </template>

        <template v-if="!$auth.loading">
            <router-link class="labels" style="color: orange" to='/Logout'><i
                    class="fa fa-user-circle"></i>Logout</router-link>
        </template>

    </div>
</template>

<script>
import AdminReportsDialog from '@/views/AdminReportsDialog'
import LoginAsDialog from '@/components/LoginAsDialog.vue'
import JohnDeereAccess from '@/components/jd/JohnDeereAccess.vue'

export default {
    props: ['activePage'],
    inject: ["$env", "$auth"],
    data: function () {
        return {
            show: true
        }
    },
    computed: {
        puppetUser: vm => vm.$store.state.puppetUser,
        myAccountEditProfileUrl: vm => vm.$env.VUE_APP_MY_ACCOUNT_EDIT_PROFILE_URL,
        // vm.$store.state.env.VUE_APP_MY_ACCOUNT_EDIT_PROFILE_URL,
        isAdmin: vm => {
            const user = vm.$auth.user.value
            if (!user) {
                return false
            }

            const roles = user['https://deltaplastics.com/roles']
            if (!roles) {
                return false
            }

            return roles.some((role) => role == 'PP Admin')
        },
        isJohnDeereDealer: vm => {
            const user = vm.$auth.user.value
            if (!user) {
                return false
            }
            
            const roles = user['https://deltaplastics.com/roles']
            if (!roles) {
                return false
            }

            return roles.some((role) => role === 'John Deere Dealer')
        }
    },
    methods: {
        openJohnDeere() {
            this.$router.push({ name: 'JohnDeere' })
        },
        openHomePage() {
            this.$router.push({ name: 'Home' })
        },
        async routeTransferFarm() {
            this.$router.push({ name: 'TransferFarms' })
        },
        async reports() {
            await this.$dialog.push(AdminReportsDialog)
        },
        async loginAs() {
            await this.$dialog.push(LoginAsDialog)
        },
        async jdAccess() {
            await this.$dialog.push(JohnDeereAccess)
        },
        async logoutAs() {
            await this.$store.dispatch('setPuppetUser', null)
        }
    }
}
</script>

<style lang="css" scoped>
@media (max-width: 700px) {
    .labels {
        margin: 3px;
        font-size: small;
        font-weight: bold;
        color: var(--secondary-light);
        align-self: center;
        align-items: center;
    }

    .labels :hover {
        color: white;
    }
}

.anchor {
    text-decoration-line: none !important;
}

#pageIsActive {
    text-decoration: underline;
}

.main_menu {
    border-radius: var(--general-border-radius);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}

.labels {
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0.1em 0.5em;
    text-decoration: none;
    font-size: small;
    font-weight: bold;
    color: var(--secondary-light);
    align-self: center;
    align-items: center;
    display: inline-flex;
}

.labels:hover {
    color: white;
}

.labels.jd {
    color: var(--jd-yellow);
}

.jd-ops-center-img {
    min-width: 100px;
    height: 4em;
    cursor: pointer;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.jd-ops-center-img:hover {
    min-width: 100px;
    height: 4em;
    cursor: pointer;
    margin-left: 0.5em;
    margin-right: 0.5em;
    scale: 1.05;
}
</style>
