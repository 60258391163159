<template>
    <div className="select-farm-header">
        <div
            style="display: flex;flex-direction: row; align-items: center; justify-content: center; padding: var(--general-padding);">
            <div style="flex-grow: 0;">
                <a href='https://www.deltaplastics.com' target="_blank" title="Delta Plastics Homepage">
                    <img src='@/assets/DPREV_allwhite.svg' alt="Delta Plastics Logo" class="delta-plastics-logo"
                        width="160">
                </a>
            </div>

            <div style="flex-grow: 1; display: flex; justify-content: flex-end;">
                <select-farm-menu :activePage="props.title" />
            </div>
        </div>

        <div class="bottom-half">
            <div v-if="!defaultDescriptionActive" class="mode-and-verbiage">
                <div class="mr-1" v-if="isAdmin">
                    <label for="farm-mode" class="label_margin_right">Mode:</label>
                    <select id="farm-mode" v-model="data.farmsMode">
                        <option value="default">Default</option>
                        <option value="jd">John Deere</option>
                    </select>
                </div>

                Click a farm or <router-link class="button small ml-1 mr-1" to="/AddFarm"><i class="fa fa-plus"></i>Add a
                    New Farm</router-link> to group fields and begin.
            </div>
            <div v-else class="mode-and-verbiage">
                {{ descriptionText }}
            </div>

            <div v-if="props.showFarmSearchText" class="farm-search-div">
                <label v-show="farmSearchText?.length" v-text="props.searchFarmsLabel" class="button_margin_right"></label>

                <input class="filter_input" type="text" onfocus="this.placeholder = ' '"
                    onblur="this.placeholder='Filter Farms'" placeholder="Filter Farms" v-model="farmSearchText">
                <a v-if="farmSearchText?.value?.length" @click="clearFarmFilter()"
                    class="button button_margin_left">Clear</a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { useAuth0 } from '@auth0/auth0-vue'

import SelectFarmMenu from './SelectFarmMenu.vue'

const farmSearchText = defineModel('farmSearchText', {
    default: null, required: false
}) // when parent component uses vue3, can name this

const props = defineProps({
    title: {
        type: String,
        default: 'Select Farm',
        required: false,
    },
    descriptionText: {
        type: String,
        default: "",
        required: false
    },
    showFarmSearchText: {
        type: Boolean,
        required: false,
        default: true
    },
    searchFarmsLabel: {
        type: String,
        required: false,
        default: ''
    }
})

const store = useStore();
const auth0 = useAuth0()

const data = reactive({
    farmsMode: store.state.farmsMode
})

function clearFarmFilter() {
    farmSearchText.value = ""
}

watch(() => data.farmsMode, (newVal) => {
    store.dispatch('setFarmsMode', newVal)
})

const isAdmin = computed(() => {
    const user = auth0?.user?.value
    if(! user) {
        return false
    }
    
    const roles = user["https://deltaplastics.com/roles"] || []

    return roles.some((role) => role == "PP Admin");
})

const defaultDescriptionActive = computed(() => {
    if (props.descriptionText) { //this feels...inverted? lol
        return true
    }
    else {
        return false
    }
})
</script>

<style lang="css" scoped>
@media (max-width: 900px) {
    .delta-plastics-logo {
        display: none;
    }
}
 
.select-farm-header {
    display: flex;
    flex-direction: column;
    z-index: var(--z-index-menu);
    position: sticky;
    top: 0px;
    left: 0px;
    opacity: 1.0;
    background-color: var(--bg1);
}

.bottom-half {
    display: flex;
    flex-direction: column;
    background-color: var(--bg0);
}

.mode-and-verbiage {
    display: flex;
    font-style: italic;
    flex-direction: row;
    background-color: var(--bg0);
    padding: var(--general-padding) 0 0;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--general-padding);
}

.delta-plastics-logo {
    width: 14em;
    min-height: 2em;
}

.farm-search-div {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    margin-right: var(--general-padding);
    text-align: center;
    background-color: var(--bg0);

}</style>